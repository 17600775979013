$navbar-height: 6rem;
$navbar-item-img-max-height: 4.75rem;

$footer-padding: 1rem 1.5rem 1rem;
// $primary: #1ebea5;
$primary: #ed9560;

@import "~bulma/bulma";

.footer .container .content a {
    color: #fff !important;
}

.is-success{
    background-color: #fff !important;
    color: #00adcd !important;
}

.is-primary{
    background-color: #fff !important;
    color: #00adcd !important;
}



div.hide {
    display: none !important;
}
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.whatslink.hero {
    h3.blog {
        margin-top: 1.5rem;
    }
    .container-help {
        padding: 24px 12px;
        background-color: rgba(76, 175, 80, 0.3);
        a {
            color: #fff;
        }
    }
    .container-news {
        margin-bottom: 8px;
        background-color: #1113012b;
        
            }
    .text-help ol li {
        margin-left: 12px;
    }
    .hero-head {
        // background-image: url(./media/whats_bege.jpg);
        background-color: #f2f2f2;
    }
    .hero-body {
        // background-image: url(./media/whats_mural_verde.jpg);
        background-color: #00adcd;
        padding-bottom: 1rem;
        

        .columns.link-view {
            margin-top: 1rem;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            padding-bottom: 1.5rem;
        }

        .select select:not([multiple]) {
            padding-right: 1.5em;
        }
    }
    .whatslink-title {
        max-width: $desktop - (2 * $gap);
        padding-top: 3rem;
    }

    .hero-footer {
        padding-top: 40px;
        padding-bottom: 40px;
        .columns.detail {
            .column .image.is-256x256 {
                margin: 0 auto;
            }
            .has-text-justified {
                padding: 0 1rem;
            }
        }
    }

    .nopadding {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .field .react-phone-number-input__row {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        .react-phone-number-input__icon {
            width: 80%;
        }
        .control:not(:first-child):not(:last-child) .button,
        .control:not(:first-child):not(:last-child) .input,
        .control:not(:first-child):not(:last-child) .select select {
            border-radius: 0;
        }

        .control:first-child:not(:only-child) .select select {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .control:last-child:not(:only-child) .button,
        .control:last-child:not(:only-child) .input,
        .control:last-child:not(:only-child) .select select {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
    .button.is-text:hover {
        background-color: transparent;
        border-color: transparent;
    }
    .field {
        .help {
            align-self: center;
            margin-left: 8px;
        }
    }
}

@media screen and (max-width: 768px) {
    .columns {
        margin-left: 0;
        margin-right: 0;
    }
}
.whatslink-why.hero {
    .hero-body {
        // background-image: url(./media/whats_bege.jpg);
        background-color: #f2f2f2;

        .image.phone {
            height: 500px;
        }
        .columns.detail {
            .column .image.is-128x128, .column .image.phone {
                margin: 0 auto;
            }
            .has-text-justified {
                padding: 0 1rem;
            }
        }
    }
}

.image.is-256x256 {
    height: 256px;
    width: 256px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

div.container.ads {
    margin-top: 1rem;
}
ins.adsbygoogle { width: 100%; height: 250px; }
@media(min-width: 500px) { ins.adsbygoogle { height: 280px; } }
@media(min-width: 800px) { ins.adsbygoogle { height: 600px; } }
